<template>
  <v-container
    id="login"
    fluid
    tag="section"
  >
    <v-dialog
      v-model="carga"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          INICIANDO SESIÓN
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      absolute
      touchless
      width="100%"
      :src="imgFondo"
      permanent
    >
      <br><br><br><br><br>
      <base-material-card
        color="white"
        max-width="85%"
        width="450"
        class="px-5 py-3 mx-auto"
      >
        <template
          #heading
        >
          <div
            class="text-center"
          >
            <v-img
              :src="imgLogo"
              max-width="320"
            />
          </div>
        </template>

        <v-card-text
          class="text-center"
          color="blue"
        >
          <v-form
            ref="form"
            lazy-validation
            color="blue"
          >
            <!-- <span
                class="font-weight-bold"
              >
                DEPOSITOS JUDICIALES
              </span> -->
            <v-text-field
              v-model="usuario"
              :disabled="carga"
              :rules="regla"
              class="mb-8 font-weight-bold"
              label="USUARIO"
              solo-inverted
              dense
              clearable
              append-icon="mdi-account-search"
              onkeydown="if (event.keyCode == 13)
                        document.getElementById('btnLog').click()"
            />
            <v-text-field
              v-model="clave"
              :disabled="carga"
              :rules="regla"
              type="password"
              class="mb-8 font-weight-bold"
              label="CONTRASEÑA"
              solo-inverted
              dense
              clearable
              append-icon="mdi-lock"
              onkeydown="if (event.keyCode == 13)
                        document.getElementById('btnLog').click()"
            />
          </v-form>
          <v-btn
            id="btnLog"
            color="primary"
            block
            max-height="38"
            @click="loginData()"
          >
            <v-icon left>
              mdi-scale-balance
            </v-icon>
            Ingresar
          </v-btn>
        </v-card-text>
      </base-material-card>
      <div class="text-body-1 text-center font-weight-light white--text">
        <span
          @click=" descargarManual('./assets/manualRij.pdf', 'manualObservatorio.pdf')"
        > Manual de usuario <v-icon color="white">
          mdi-book-open-outline
        </v-icon></span><br>
        {{ new Date().getFullYear() }} &copy; CSJLA - <v-icon color="white">
          mdi-space-invaders
        </v-icon>
      </div>
      <v-snackbar
        v-model="error"
        bottom
        tile
        color="red accent-2"
      >
        <div class="text-center">
          <v-icon>
            mdi-alert-circle
          </v-icon> {{ textoError }}
        </div>
      </v-snackbar>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'Login',
    components: {
    },

    data: () => ({
      usuario: '',
      clave: '',
      carga: false,
      sedeCorteData: '',
      regla: [v => !!v || 'El campo es requerido'],
      imgFondo: '',
      imgLogo: '',
      error: false,
      textoError: '',
    }),
    created () {
      this.imgFondo = localStorage.getItem('imgFondo')
      this.imgLogo = localStorage.getItem('imgLogo')
      if (localStorage.getItem('usuarioOdajup')) {
        this.$router.replace(
          { path: 'registro' },
          () => {
            this.$router.go(0)
          },
        )
      }
    },
    mounted () {
      this.$store.commit('SET_VALOR', true)
    },
    methods: {
      descargarManual (url, name) {
        var link = document.createElement('a')
        link.download = name
        link.href = url
        link.setAttribute('target', '_blank')
        link.click()
        link.remove()
      },
      loginData () { /*
        this.$router.replace(
          { path: 'persona' },
          () => {
            this.$router.go(0)
          },
        ) */
        if (this.$refs.form.validate()) {
          this.carga = true
          const data = {
            usuario: this.usuario,
            contrasena: this.clave,
          }
          axios
            .post(`${localStorage.getItem('urlApiOdajup')}/login`, data)
            .then((r) => {
              console.log(r)
              if (r.data.success === true) {
                localStorage.setItem('usuarioOdajup', JSON.stringify(r.data.data[0]))
                localStorage.setItem('tokenOdajup', r.data.token)
                this.carga = false
                // this.$router.replace('registro')
                this.$router.replace(
                  { path: 'registro' },
                  () => {
                    this.$router.go(0)
                  },
                )
              } else {
                this.carga = false
                this.error = true
                this.textoError = 'Usuario o contraseña incorrecta'
              }
            })
            .catch((err) => {
              alert('Servicio no disponible' + err)
            })
        }
      },
    },
  }
</script>
